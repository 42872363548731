import clsx from 'clsx';
import { HTMLAttributes } from 'react';

import styles from './Pagination.module.scss';

type Props = HTMLAttributes<HTMLDivElement> & {
  currentIndex: number;
  numberOfBullets: number;
  // eslint-disable-next-line no-unused-vars
  onBulletClick?: (index: number) => void;
};

export const Pagination = ({
  className,
  currentIndex,
  numberOfBullets,
  onBulletClick,
  ...rest
}: Props) => (
  <div className={clsx(styles.wrapper, className)} {...rest}>
    {[...new Array(numberOfBullets)].map((_, index) => (
      <span
        key={index}
        className={clsx(styles.bullet, currentIndex === index && styles.active)}
        onClick={() => onBulletClick?.(index + 1)}
      />
    ))}
  </div>
);
