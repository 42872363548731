// eslint-disable-next-line no-restricted-imports
import { StaticImageData } from 'next/image';
import { ReactNode } from 'react';

import { formatPhoneNumber } from '@/helpers/string/formatPhoneNumber';

import { SOCIAL_LINKS } from '@/constants/social.constant';

import * as Images from './assets';

interface IItem {
  title: string;
  body: string | ReactNode;
  image?: string | StaticImageData;
  modalContent: {
    title: string;
    body: string | ReactNode;
    footer: string[];
  };
}

export const deliveryItems: IItem[] = [
  {
    title: 'Бесплатная доставка',
    body: <>Бесплатно доставим заказ по&nbsp;Москве в пределах МКАД</>,
    image: Images.FreeDeliveryImage,
    modalContent: {
      title: 'Бесплатная 3-х часовая доставка в пределах МКАД',
      body: (
        <>
          Бесплатная доставка осуществляется ежедневно с 10:00 до 02:00 ч. в выбранный вами 3
          -часовой интервал. <br /> Важно! При выборе данного вида доставки нельзя дополнительно
          указать точное время получения букета.
        </>
      ),
      footer: ['Бесплатно'],
    },
  },
  {
    title: 'Доставим ко времени',
    body: 'Соберем, упакуем, добавим бесплатный подарок и привезем получателю в часовой интервал',
    image: Images.JustInTimeDeliveryImage,
    modalContent: {
      title: 'Доставим ко времени 60 минут',
      body: 'Самый точный платный вариант доставки. Заказ приедет к определенному времени. Точность доставки 60 минут.',
      footer: ['от 995 ₽'],
    },
  },
  {
    title: 'Экспресс',
    body: <>Соберем заказ и доставим в 2&nbsp;-&nbsp;часовой интервал</>,
    image: Images.ExpressDeliveryImage,
    modalContent: {
      title: 'Экспресс-доставка за 2 часа',
      body: 'Платная доставка с интервалом в 2 часа. Рассчитывается автоматически на сайте.',
      footer: ['от 495 ₽'],
    },
  },
  {
    title: 'Заказ по телефону',
    body: (
      <>
        <div>Наши менеджеры помогут подобрать&nbsp;букет</div>
        <div>
          <div>Принимаем заказы с&nbsp;9:00&nbsp;до&nbsp;02:00</div>
          <div>
            По номеру: {formatPhoneNumber(SOCIAL_LINKS.main_phone_number, { mode: 'onlySpaces' })}
          </div>
        </div>
      </>
    ),

    image: Images.SupportImage,
    modalContent: {
      title: 'Заказать по телефону',
      body: (
        <>
          <span style={{ display: 'inline-block', font: 'inherit', marginBottom: '12px' }}>
            Наши менеджеры помогут подобрать букет с 9:00 до 02:00
          </span>
          <br />
          Позвоните по номеру{' '}
          <a
            href={`tel:${formatPhoneNumber(SOCIAL_LINKS.main_phone_number)}`}
            style={{ font: 'inherit' }}
          >
            {formatPhoneNumber(SOCIAL_LINKS.main_phone_number, { mode: 'onlySpaces' })}
          </a>{' '}
          и скажите, что хотите сделать заказ — дальше менеджеры сориентируют вас. Доставка букетов
          с 10:00 до 02:00 без выходных.
        </>
      ),
      footer: [],
    },
  },
];
