import Link from 'next/link';

import { Image } from '@/components/image';

import { ITiledLink } from '@/types/new/tiled-links';

import styles from './SelectionItem.module.scss';

export const SelectionItem = ({ name, href, image }: ITiledLink) => {
  return (
    <Link href={href} className={styles.wrapper}>
      <span className={styles.title}>{name}</span>
      {image && (
        <Image
          width={220}
          height={190}
          src={image.formats?.small?.url || image.url}
          alt={`Изображение для подборки ${name}`}
          className={styles.image}
          style={{ height: 'auto' }}
        />
      )}
    </Link>
  );
};
