import clsx from 'clsx';
// eslint-disable-next-line no-restricted-imports
import { StaticImageData } from 'next/image';
import { HTMLAttributes, ReactNode, useState } from 'react';

import { Image } from '@/components/image';

import useSectionVisible from '@/hooks/useSectionVisible';

import styles from './DeliveryGridMap.module.scss';
import { deliveryItems } from './config';
import { DeliveryModal } from './delivery-modal/DeliveryModal';
import { Map } from './map/Map';

type Props = HTMLAttributes<HTMLDivElement> & {};

export const DeliveryGridMap = ({ className, ...rest }: Props) => {
  const [modalContent, setModalContent] = useState<
    (typeof deliveryItems)[0]['modalContent'] | null
  >(null);
  const [componentRef, sectionVisible] = useSectionVisible();
  return (
    <div className={clsx(styles.wrapper, className)} {...rest} ref={componentRef}>
      <h2 className={styles.title}>Бесплатная доставка по Москве и МО</h2>
      {sectionVisible && (
        <>
          <div className={styles.grid}>
            {deliveryItems.map((el) => (
              <DeliveryGridItem
                title={el.title}
                image={el.image}
                body={el.body}
                key={el.title}
                onClick={() => setModalContent(el.modalContent)}
              />
            ))}
          </div>
          <Map className={styles.map} />
        </>
      )}
      <DeliveryModal content={modalContent} onClose={() => setModalContent(null)} />
    </div>
  );
};

const DeliveryGridItem = ({
  title,
  image,
  body,
  ...rest
}: HTMLAttributes<HTMLDivElement> & {
  title: string;
  image?: StaticImageData | string;
  body: ReactNode;
}) => {
  return (
    <div className={styles.item} {...rest}>
      <div className={styles.title}>{title}</div>
      <div className={styles.body}>{body}</div>
      {image && <Image className={styles.image} src={image} alt={title} />}
    </div>
  );
};
