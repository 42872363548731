import clsx from 'clsx';
import { HTMLAttributes } from 'react';

import styles from './NavigationButton.module.scss';

type Props = HTMLAttributes<HTMLButtonElement> & {
  direction: 'left' | 'right';
};

export const NavigationButton = ({ className, direction, ...rest }: Props) => (
  <button className={clsx(styles.button, className)} aria-label={direction + 'slide'} {...rest}>
    {direction === 'left' && (
      <span className={styles.arrow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M15 5L9 12L15 19"
            stroke="#1C1C1C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    )}
    {direction === 'right' && (
      <span className={styles.arrow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M9 5L15 12L9 19"
            stroke="#202020"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    )}
  </button>
);
