import { GetServerSidePropsContext, GetServerSidePropsResult, NextPage } from 'next';
import { NextSeo } from 'next-seo';
import React from 'react';

import env from '@/helpers/server/env';

import { Home } from '@/pages/Home';

import { BannersService } from '@/services/banners.service';
import { ProductService } from '@/services/product.service';
import { TiledBundlesService } from '@/services/tiled-bundles.service';
import { TiledLinksService } from '@/services/tiled-links.service';

import { IBanner } from '@/types/new/banners';
import { IRubricBundle } from '@/types/new/products';
import { ITiledBundle } from '@/types/new/tiled-bundles';
import { ITiledLinkBundleExtended } from '@/types/new/tiled-links';

import { getLogger } from '@/config/log-util';

export type HomeProps = {
  rubricBundles: Awaited<ReturnType<typeof getRubricBundleById>>[];
  links: Awaited<ReturnType<typeof getLinks>>;
  bundle: Awaited<ReturnType<typeof getBundle>>;
  banners: IBanner[];
};

const logger = getLogger('pages/index.tsx');

async function getLinks(id: number): Promise<ITiledLinkBundleExtended | null> {
  try {
    const { data } = await TiledLinksService.getLinksBundleById(id);
    return { id: data.id, name: data.name, links: data.links?.sort((a, b) => a.order - b.order) };
  } catch (e) {
    logger.error(e);
    return null;
  }
}

async function getBundle(id: number): Promise<ITiledBundle | null> {
  try {
    const { data } = await TiledBundlesService.getById(id);

    return data;
  } catch (e) {
    return null;
  }
}

async function getBanners(): Promise<IBanner[]> {
  try {
    const { data } = await BannersService.getBanners();

    return data.items?.length ? data.items : [];
  } catch (e) {
    return [];
  }
}

async function getRubricBundleById(id: string | number): Promise<IRubricBundle | null> {
  try {
    const { data } = await ProductService.getRubricBundleById(id);

    return data.items.length ? data : null;
  } catch (e) {
    return null;
  }
}

export async function getServerSideProps(
  context: GetServerSidePropsContext
): Promise<GetServerSidePropsResult<HomeProps>> {
  context.res.setHeader('Cache-Control', 'public, s-maxage=60, stale-while-revalidate=120');

  try {
    const [links, bundle, banners, rubricBundles] = await Promise.all([
      getLinks(+env.SELECTION_BUNDLE_ID),
      getBundle(+env.TILED_BUNDLE_ID),
      getBanners(),
      Promise.all(env.RUBRIC_BUNDLE_IDS.map((id) => getRubricBundleById(id))),
    ]);

    return {
      props: {
        rubricBundles: rubricBundles.filter(Boolean) as IRubricBundle[],
        links,
        bundle,
        banners,
      },
    };
  } catch (error) {
    logger.error('Ошибка при получении данных для главной страницы:', error);
    return {
      props: {
        rubricBundles: [],
        links: null,
        bundle: null,
        banners: [],
      },
    };
  }
}

const HomePage: NextPage<HomeProps> = (props) => {
  return (
    <>
      <NextSeo />
      <Home {...props} />
    </>
  );
};
export default HomePage;
