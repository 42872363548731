import dynamic from 'next/dynamic';
import React, { useRef } from 'react';

import { Container } from '@/components/container';
import { IndividualOrder } from '@/components/individual-order';

import { BannerSlider } from '@/pages/Home/banner-slider/BannerSlider';
import RubricsTabsSection from '@/pages/Home/rubrics-tabs-section/RubricsTabsSection';

import { HomeProps } from '../../../pages';
import MainLayout from '../../components/layout/main-layout/MainLayout';
import VisuallyHiddenBlock from '../../components/shared/VisuallyHiddenBlock';

import styles from './Home.module.scss';
import { AdvantagesSlider } from './advantages-slider/AdvantagesSlider';
import { DeliveryGridMap } from './delivery-grid-map/DeliveryGridMap';
import { SelectionsGrid } from './selections-grid/SelectionsGrid';
import { SelectionsSlider } from './selections-slider/SelectionsSlider';

const Reviews = dynamic(() => import('@/components/reviews/Reviews'), {
  ssr: false,
});
const YandexReviews = dynamic(() => import('@/components/yandex-reviews/YandexReviews'), {
  ssr: false,
});

export default function Home({ rubricBundles, links, bundle, banners }: HomeProps) {
  const individualOrderSection = useRef(null);

  return (
    <MainLayout>
      <VisuallyHiddenBlock>
        <h1>AzaliaNow — цветы с доставкой по Москве</h1>
      </VisuallyHiddenBlock>

      <div className={styles.wrapper}>
        {banners.length > 0 && (
          <Container>
            <BannerSlider banners={banners} />
          </Container>
        )}

        {bundle && <SelectionsGrid bundle={bundle} />}

        {rubricBundles.map((rubricBundle) => {
          if (!rubricBundle) return null;

          return (
            <Container key={rubricBundle.id}>
              <RubricsTabsSection rubricBundle={rubricBundle} />
            </Container>
          );
        })}

        {links && (
          <Container>
            <SelectionsSlider bundle={links} />
          </Container>
        )}

        <Container>
          <AdvantagesSlider />
        </Container>

        <Container>
          <DeliveryGridMap />
        </Container>

        <Reviews ids={[18, 71, 40, 70, 69, 48, 68, 67, 32, 58, 61, 27, 28, 34, 37, 47, 60]} />
        <YandexReviews variant="mainPage" />

        <Container className={styles.individual_order_modal}>
          <div ref={individualOrderSection}>
            <IndividualOrder />
          </div>
        </Container>
      </div>
    </MainLayout>
  );
}
