import clsx from 'clsx';
import { HTMLAttributes, useState } from 'react';

import { Image } from '@/components/image';

import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useTypedSelector } from '@/hooks/useTypedSelector';

import {
  OfficeMapImage,
  OfficeMapImageMobile,
  PickupMapImage,
  PickupMapImageMobile,
} from '@/pages/Home/delivery-grid-map/assets';

import styles from './Map.module.scss';

type Props = HTMLAttributes<HTMLDivElement> & {};

export const Map = ({ className, ...rest }: Props) => {
  const [tabIndex, setTabIndex] = useState<0 | 1>(0);
  const isMobile = useMediaQuery('425px');
  const { cityPickupPoint } = useTypedSelector((state) => state.city);
  const handleTabClick = (index: 0 | 1) => {
    setTabIndex(index);
  };

  return (
    <div className={clsx(styles.wrapper, className)} {...rest}>
      <Image
        src={
          tabIndex === 0
            ? isMobile
              ? PickupMapImageMobile
              : PickupMapImage
            : isMobile
            ? OfficeMapImageMobile
            : OfficeMapImage
        }
        className={clsx(styles.image, styles[tabIndex === 0 ? 'pickup' : 'office'])}
        alt={
          tabIndex === 0
            ? 'Изображение точки склада AzaliaNow на Яндекс карте'
            : 'Изображение офиса AzaliaNow на Яндекс карте'
        }
      />
      <div className={styles.information}>
        <div className={styles.tabs}>
          <button
            className={clsx(styles.tab, tabIndex === 0 && styles.active)}
            onClick={() => handleTabClick(0)}
          >
            Самовывоз
          </button>
          <button
            className={clsx(styles.tab, tabIndex === 1 && styles.active)}
            onClick={() => handleTabClick(1)}
          >
            Офис
          </button>
        </div>
        {tabIndex === 0 ? (
          <>
            <p className={styles.description}>
              Наши флористы соберут и упакуют, вам остается только забрать свой заказ в любое время
              с 10:00 до 00:00 или по согласованию с менеджером
            </p>
            <address className={styles.address}>{cityPickupPoint}</address>
          </>
        ) : (
          <address className={styles.address}>
            {' '}
            г. Москва, 1-й Магистральный проезд, д. 12, стр. 1, эт. 2, оф. 201
          </address>
        )}
      </div>
    </div>
  );
};
