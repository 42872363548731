import clsx from 'clsx';
import { useInView } from 'framer-motion';
import Link from 'next/link';
import { HTMLAttributes, useEffect, useRef } from 'react';

import { Image } from '@/components/image';

import { analyticMetric } from '@/helpers/analytics-metric';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useVisibleItems } from '@/hooks/useVisibleItems';

import { toggleModal } from '@/store/slices/mobile-applicationn-qr-modal/mobile-application-qr-modal';

import styles from './AdvantagesSider.module.scss';
import * as Images from './assets';

interface ISlide {
  title: string;
  media: string;
  href: string;
  titleColor: string;
  popup?: boolean;
}

const items = [
  {
    title: 'Выбирайте букеты и цветы',
    media: Images.FirstSlideImage,
    href: '/rubric/top100',
    titleColor: '#1B1B1B',
  },
  {
    title: 'Оплачивайте как удобно',
    media: Images.SecondSlideImage,
    href: '/payment',
    titleColor: '#FFF',
  },
  {
    title: 'Контролируйте свой заказ',
    media: Images.ThirdSlideImage,
    href: 'https://qr.azalianow.ru/universal/web-site-appstore-googleplay',
    titleColor: '#FFF',
    popup: true,
  },
  {
    title: 'Получайте 100% свежесть',
    media: Images.FourthSlideImage,
    href: '/delivery',
    titleColor: '#FFF',
  },
];
const promotions = [
  // "Выбирайте букеты и цветы"
  {
    id: 'az_now_simple_1', // prefix entity
    name: 'AzaliaNow - Это просто',
    creative_name: 'Выбирайте букеты и цветы',
    position: 1,
  },
  // "Оплачивайте как удобно"
  {
    id: 'az_now_simple_2', // prefix entity
    name: 'AzaliaNow - Это просто',
    creative_name: 'Оплачивайте как удобно',
    position: 2,
  },
  // "Контролируйте свой заказ"
  {
    id: 'az_now_simple_3', // prefix entity
    name: 'AzaliaNow - Это просто',
    creative_name: 'Контролируйте свой заказ',
    position: 3,
  },
  // "Получайте 100% свежесть"
  {
    id: 'az_now_simple_4', // prefix entity
    name: 'AzaliaNow - Это просто',
    creative_name: 'Получайте 100% свежесть',
    position: 4,
  },
];

export const AdvantagesSlider = ({ className, ...rest }: HTMLAttributes<HTMLDivElement>) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const isTablet = useMediaQuery('770px', 'max-width');
  const { visibleItems, containerRef } = useVisibleItems(items, 2);
  const isInView = useInView(wrapperRef, { once: true });

  useEffect(() => {
    isInView && analyticMetric.viewingPromotions(promotions);
  }, [isInView]);

  const onAdvantagesSlideClickHandle = (position: number) => {
    dispatch(toggleModal());
    analyticMetric.selectPromotion(promotions[position]);
  };

  return (
    <div className={clsx(styles.wrapper, className)} ref={wrapperRef} {...rest}>
      <h2 className={styles.title}>AzaliaNow - Это просто!</h2>
      <div className={styles.slider} ref={containerRef}>
        {visibleItems.map(({ title, href, media, titleColor, popup }: ISlide, index: number) => {
          const withPopup = popup && !isTablet;
          const Component = withPopup ? 'button' : Link;
          const componentProps: any = withPopup
            ? { onClick: () => onAdvantagesSlideClickHandle(index) }
            : { href };

          return (
            <Component key={title} className={styles.item} {...componentProps}>
              <span className={styles.title} style={{ color: titleColor ?? '#1B1B1B' }}>
                {title}
              </span>
              <Image src={media} fill alt="" className={styles.image} />
            </Component>
          );
        })}
      </div>
    </div>
  );
};
