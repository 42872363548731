import clsx from 'clsx';
import { HTMLAttributes, useState } from 'react';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperClass } from 'swiper/types';

import { useVisibleItems } from '@/hooks/useVisibleItems';

import { ITiledLinkBundleExtended } from '@/types/new/tiled-links';

import styles from './SelectionsSlider.module.scss';
import { SelectionItem } from './selection-item/SelectionItem';

type Props = HTMLAttributes<HTMLDivElement> & {
  bundle: ITiledLinkBundleExtended;
};

export const SelectionsSlider = ({ bundle, className, ...rest }: Props) => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [swiperProgress, setSwiperProgress] = useState<number>(0);
  const [isAllVisible, setIsAllVisible] = useState<boolean>(false);
  const { visibleItems, containerRef } = useVisibleItems(bundle.links, 3);
  return (
    <div className={clsx(styles.wrapper, className)} {...rest}>
      <h2 className={styles.title}>{bundle.name}</h2>

      <div className={styles.swiper}>
        {!isAllVisible && swiperProgress !== 0 && (
          <button
            className={clsx(styles.button, styles.prev)}
            onClick={() => swiper && swiper.slidePrev()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M15 19L9 12L15 5"
                stroke="#1B1B1B"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}
        {!isAllVisible && swiperProgress !== 1 && (
          <button
            className={clsx(styles.button, styles.next)}
            onClick={() => swiper && swiper.slideNext()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M9 5L15 12L9 19"
                stroke="#202020"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}
        <Swiper
          ref={containerRef}
          slidesPerView="auto"
          watchSlidesProgress
          onInit={(swiper: SwiperClass) => {
            const isAllSlidesVisible = swiper.slides.every((slide: Element) =>
              slide.className.includes('visible')
            );
            setSwiper(swiper);
            setIsAllVisible(isAllSlidesVisible);
          }}
          onTransitionEnd={(swiper: SwiperClass) => {
            setSwiperProgress(swiper.progress);
          }}
        >
          {visibleItems?.map((item: any) => (
            <SwiperSlide key={item.link_id} className={styles.slide}>
              <SelectionItem {...item.link} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
