import Link from 'next/link';

import { ModalCloseButton } from '@/components/modal-close-button';
import ModalWrapper from '@/components/ui/modals/modal-wrapper/ModalWrapper';

import { ROUTES } from '@/constants/routes.constant';

import { deliveryItems } from '../config';

import styles from './DeliveryModal.module.scss';

type Props = {
  onClose: () => void;
  content: (typeof deliveryItems)[0]['modalContent'] | null;
};

export const DeliveryModal = ({ onClose, content }: Props) => {
  return (
    <ModalWrapper
      isOpen={!!content}
      pt={{
        modal: {
          className: styles.modal,
        },
        bottomSheet: {
          className: styles.bottomSheet,
        },
      }}
      onClose={onClose}
    >
      {content?.title && <div className={styles.title}>{content.title}</div>}
      {content?.body && <div className={styles.body}>{content.body}</div>}
      <div className={styles.footer}>
        <Link href={ROUTES.CATALOG('bukety')} className={styles.link}>
          Перейти в каталог
        </Link>
        {content?.footer && content?.footer.length > 0 && (
          <div className={styles.labels}>
            {content?.footer.map((label) => (
              <span key={label}>{label}</span>
            ))}
          </div>
        )}
      </div>
      <ModalCloseButton className={styles.closeButton} onClick={onClose} />
    </ModalWrapper>
  );
};
